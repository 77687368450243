import React, {useEffect, useRef, useState} from "react";

import {
    Table,
    Button,
    message,
    Space,
    Popconfirm,
    Popover,
    Modal,
    Card,
    Typography, Skeleton, Dropdown, Menu, Tooltip, Tag, Pagination
} from 'antd';

const { Text, Paragraph } = Typography;

import api from "../api";

import {
    CheckOutlined,
    DeleteOutlined,
    DownloadOutlined,
    DownOutlined, EditOutlined, EyeOutlined, FilePdfOutlined, FileTextOutlined, FormOutlined,
} from "@ant-design/icons";
import {Can} from "../helpers/Can";
import TimeAgo from "../helpers/TimeAgo";
import User from "../helpers/User";
import EnabledDisabled from "../helpers/EnabledDisabled";
import {NavLink, useNavigate} from "react-router-dom-v5-compat";
import {StorageFolderIcon} from "../helpers/icons";
import OrgNavLink, {useOrgPath} from "../helpers/OrgNavLink";
import TableDataDownloadButton from "../widgets/TableDataDownloadButton";
import useCurrentOrg from "../helpers/useCurrentOrg";
import StateIcon from "../widgets/StateIcon";
import {useTranslation} from "react-i18next";
import PaginationTotal from "../widgets/PaginationTotal";

export default ({newAccessRequest, q, onLoaded}) => {
    const {t} = useTranslation();

    const [data, setData] = useState([]);
    const [pagination, setPagination] = useState({current: 1, pageSize: 10});
    const [loading, setLoading] = useState(false);

    const apiPath = '/api/access_requests?submitted=true'

    const destroy = id => {
        console.log(id);
        api({
            url: `${apiPath}/${id}`,
            method: 'delete'
        }).then(res => {
            message.success(t('message-access-request-deleted','Access Request Deleted.'));
            handleTableChange();
        });
    }

    const getPath = useOrgPath()

    const Actions = ({access_request}) => {
        const [modalVisible, setModalVisible] = useState();
        const [formVisible, setFormVisible] = useState();

        const editHash = `edit-${access_request.id}`;

        const edit = ()=> {
            window.location.hash = editHash;
            setModalVisible(false);
            setFormVisible(true)
        }

        const onClose = ()=> {
            history.replaceState(null, null, ' ');
            setFormVisible(false)
        }

        const onSave = ()=> {
            onClose()
            handleTableChange()
        }

        if(window.location.hash == `#${editHash}` && !formVisible) {
            setFormVisible(true);
        }

        const viewHash = `view-${access_request.id}`;

        const navigate = useNavigate();

        const [currentAccessRequest, setCurrentAccessRequest] = useState()

        const view = (id) => {
            setFormVisible(false)
            setModalVisible(true)
            window.location.hash = viewHash;

            api(`${apiPath}/${id}`).then(res => {
                setCurrentAccessRequest(res.data);
            })
        }

        if(window.location.hash == `#${viewHash}` && !modalVisible) {
            view(access_request.id)
        }

        const modalClose = ()=> {
            setModalVisible(false);
            history.replaceState(null, null, ' ');
        }

        const clickRespond = ()=>{
            navigate(getPath(`/access-requests/${access_request.slug}`))
        }

        return (
            <>
                <Space size={5}>
                    {access_request.aasm_state == 'submitted' ? (
                        <Button icon={<CheckOutlined/>} onClick={clickRespond} type={'primary'} ghost size={'small'}>{t('button-respond','Respond')}...</Button>
                    ) : (
                        <Space>
                            <Button icon={<EyeOutlined/>} onClick={clickRespond} size={'small'}>{t('view','View')}...</Button>

                            {access_request.code && (
                                <Button icon={<FilePdfOutlined/>} href={`/access-requests/${access_request.code}/receipt`} size={'small'} target={'_blank'}>{t('button-receipt','Receipt')}</Button>
                            )}
                        </Space>
                    )}

                    {access_request.editable && (
                        <Space direction={'horizontal'}>

                            <Popconfirm
                                title={t('archive-access-request','Archive Access Request?')}
                                onConfirm={() => destroy(access_request.id)}
                            >
                                <Button size={'middle'}>
                                    <DeleteOutlined/>
                                </Button>
                            </Popconfirm>

                        </Space>
                    )}
                </Space>
            </>
        );
    }

    const columns = [
        {
            title: t('status','Status'),
            dataIndex: 'aasm_state',
            sorter: true,
            width: 1,
            filters: [
                {text: t('submitted','Submitted'), value: 'submitted'},
                {text: t('finalized','Finalized'), value: 'finalized'},
                {text: t('revoked','Revoked'), value: 'revoked'},
            ],
            filterMultiple: true,
            render: (state, access_request)=> (
                <StateIcon state={state}/>
            )
        },
        {
            title: t('request-submitted','Request Submitted'),
            dataIndex: 'submitted_at',
            sorter: true,
            render: submittedAt => <TimeAgo date={submittedAt}/>,
        },
        {
            title: t('requester','Requester'),
            render: (ar) => {
                return <User user={ar.user}/>
            },
        },
        {
            title: t('name','Name'),
            dataIndex: 'name',
            render: (name, accessRequest) => {
                return (
                    <>{name} {accessRequest.description?.length > 0 && (<Popover overlayStyle={{maxWidth:300}} content={accessRequest.description}><FileTextOutlined/></Popover>)}</>
                )
            }
        },
        {
            title: t('deadline','Deadline'),
            dataIndex: 'request_deadline',
            sorter: true,
            render: request_deadline => <TimeAgo date={request_deadline}/>,
        },
        {
            title: t('requested-assets','Requested Assets'),
            dataIndex: 'requested_assets_count',
            sorter: true,
            width: 1,
        },
        {
            title: t('granted-assets','Granted Assets'),
            dataIndex: 'granted_assets_count',
            sorter: true,
            width: 1,
        },
        {
            key: 'action',
            width: 100,
            render: access_request => <Actions access_request={access_request}/>
        }
    ];

    const params = useRef({})

    const [settings, setSettings] = useState({pagination})

    const handleTableChange = (pagination=settings.pagination, filters=settings.filters, sorter=settings.sorter) => {
        setSettings({pagination, filters, sorter, q})
        params.current = {
            q,
            sortField: sorter?.field,
            sortOrder: sorter?.order,
            pagination,
            ...filters,
        }
        loadTableData();
    }

    const loadTableData = ()=>{
        if(!params.current?.pagination) return

        setLoading(true);
        api.get(apiPath, { params: params.current }).then(res => {
            setData(res.data)
            setPagination({
                ...params.current.pagination,
                total: res.headers['total-entries']
            })
            setLoading(false)
            onLoaded && onLoaded()
        })
    }

    useEffect(()=>{
        handleTableChange({...settings.pagination, current:1})
    }, [q])

    useEffect(handleTableChange, [newAccessRequest, q]);

    return (
        <>
            <Table
                bordered
                scroll={{x: true}}
                size={'small'}
                // rowSelection={{onChange}}
                columns={columns}
                rowKey={record => record.id}
                dataSource={data}
                pagination={pagination}
                loading={loading}
                onChange={handleTableChange}
                footer={()=>(
                    <div style={{display:'flex', alignItems:'center'}}>
                        <Space>
                            <TableDataDownloadButton apiPath={apiPath} settings={settings}/>
                        </Space>

                        <PaginationTotal pagination={pagination}/>
                    </div>
                )}
            />

        </>
    );
}
