import React, {useContext, useEffect, useState} from "react";
import {message, Button, Row, Col, Card, Alert, Divider} from "antd"
import setTitle from "../helpers/setTitle";
import LoginForm from "./LoginForm";
import {Link, useNavigate} from "react-router-dom-v5-compat";
import LoginOutlined from "@ant-design/icons/lib/icons/LoginOutlined";
import OrgNavLink, {OrgLink, useOrgPath} from "../helpers/OrgNavLink";
import useOrgSlug from "../helpers/useOrgSlug";
import OrgBackground from "./OrgBackground";
import OrgLogo from "../widgets/OrgLogo";


import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile
} from "device-detect";
import {SessionContext} from "../../contexts/SessionContext";
import useCurrentOrg from "../helpers/useCurrentOrg";
import useCurrentUser from "../helpers/useCurrentUser";
import {useTranslation} from "react-i18next";
import { LoadingOutlined } from "@ant-design/icons";

export const EmailLogin = ()=> <Login emailLogin/>

const Login = ({emailLogin}) => {
    const {t} = useTranslation();
    setTitle(t('login','Login'));

    const org = useCurrentOrg()

    const [loggedOut, setLoggedOut] = useState()
    useEffect(()=>{
        if(window.location.hash == '#logged-out') {
            setLoggedOut(true)
        }
    }, [])

    const navigate = useNavigate()

    const currentUser = useCurrentUser()
    if(currentUser && !currentUser.guest) {
        if(currentUser.last_organization) {
            navigate(`/${currentUser.last_organization.slug}`)
        }
        return <Alert message={<><LoadingOutlined/> {currentUser.email?.length ? t('logged-in-as-email', 'Logged in as {{email}}', {email: currentUser.email || '...'}) : t('logging-in','Logging in...')}</>} style={{margin:'2em'}}/>
    }

    return (
        <div style={{paddingTop:'2em'}}>
            <OrgBackground/>
            <Card title={<><LoginOutlined/> {org?.title || 'Mediagraph'} Login</>} style={{width: isMobile() ? '100%' : 400, margin:'0 auto'}}>
                <OrgLogo org={org}/>

                {loggedOut && <Alert message={t("message-you-have-been-logged-out","You have been logged out.")} showIcon style={{margin: '1em 0'}}/>}

                <LoginForm org={org} emailLogin={emailLogin}/>

                {(!org || !org?.hide_password_login || emailLogin) && (
                    <div style={{marginTop:'1em'}}>
                        {org && (
                            <OrgNavLink to={'/request-access'}>{t('request-access','Request Access')}</OrgNavLink>
                        ) || (
                            <OrgNavLink to={'/sign-up'}>{t('sign-up','Sign Up')}</OrgNavLink>
                        )}
                        <br/>
                        <Link to={'/forgot-password'}>{t('forgot-password','Forgot Password')}</Link>
                    </div>
                )}

                {org?.hide_password_login && !emailLogin && (
                  <div style={{textAlign: 'center'}}>
                      <Divider/>
                      <OrgLink to={'/email-login'} style={{textDecoration:'underline'}}>{t('do-not-have-org-email', "Don't have an email address for {{title}}?", {title: org.title})}</OrgLink>
                  </div>
                )}

                {org?.hide_password_login && emailLogin && (
                  <div style={{textAlign: 'center'}}>
                      <Divider/>
                      <OrgLink to={'/login'} style={{textDecoration:'underline'}}>{t('sso-login-with-org-email','SSO Login with {{title}} email address.', {title: org.title})}</OrgLink>
                  </div>
                )}
            </Card>
        </div>
    );
}

export default Login