import React from "react";
import {useEffect, useRef} from "react";
import { useCurrentAssetCursor } from "./useSetCurrentAsset";
import ViewerJS from "viewerjs";
import { useAssetsState } from "../../contexts/AssetsContext";
import { useCurrentAssetsState, useCurrentAssetsDispatch } from "../../contexts/CurrentAssetContext";

export default ({asset, ref})=> {
    const currentAssetDispatch = useCurrentAssetsDispatch()

    const openViewer = ()=> {
        console.log('openViewer', asset)
        currentAssetDispatch({type: 'setViewerEnabled', enabled: true, ref})
    }

    return openViewer
}

const AssetViewer = ()=> {
    const currentAssetCursor = useCurrentAssetCursor()

    const {currentAssetId, viewerEnabled, viewerRef} = useCurrentAssetsState()
    const {assets} = useAssetsState()
    const currentAsset = assets[currentAssetId]

    const currentAssetDispatch = useCurrentAssetsDispatch()
    const viewer = useRef()

    useEffect(()=>{
        if(viewer.current) viewer.current.destroy()

        if(viewerEnabled && currentAsset?.id) {
            const src = currentAsset?.full_url || currentAsset?.permalink_url || currentAsset?.small_url
            const el = document.createElement('img')
            el.src = src

            ViewerJS.prototype.next = ()=>{
                console.log('next')
                currentAssetCursor('next')
            }

            ViewerJS.prototype.prev = ()=>{
                console.log('prev')
                currentAssetCursor('prev')
            }

            viewer.current = new ViewerJS(el, {
                title: false,
                navbar: false,
                rotatable: false,
                transition: false,
                toolbar: {
                    flip: false,
                    zoomIn: 4,
                    zoomOut: 4,
                    prev: true,
                    next: true,
                    oneToOne: 4,
                    play: {
                        show: 4,
                        size: 'large',
                    },
                },
                viewed({target: {viewer}}) {
                    const imgHeight = el.height || el.naturalHeight
                    const ratio = window.outerHeight / imgHeight
                    console.log('viewed zoomTo', window.outerHeight, currentAsset.height, ratio)
                    viewer.zoomTo(ratio);
                },
                hidden() {
                    currentAssetDispatch({type: 'setViewerEnabled', enabled: false})
                    viewerRef?.current?.focus();
                }
            })

            viewer.current.show()
        }
        else viewer.current?.destroy()
    }, [viewerEnabled, currentAsset?.id])

    return <></>;
}

export {AssetViewer}