import React, {useContext, useEffect, useState} from "react";
import {
    Button,
    Card,
    List,
    Modal,
    message,
    Skeleton,
    Empty,
    Divider,
    Space,
    Collapse,
    Alert,
    notification,
    Tag
} from "antd";
import {CloudUploadOutlined, ClockCircleOutlined, CheckOutlined} from "@ant-design/icons";

import {Link, useNavigate} from "react-router-dom-v5-compat";

import api from "../api"
import HelpPopover from "../HelpPopover";
import {StorageFolderIcon, UserGroupIcon} from "../helpers/icons";
import {OrgLink, useOrgPath} from "../helpers/OrgNavLink";
import {SessionContext} from "../../contexts/SessionContext";

import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile
} from "device-detect";
import {useAbility} from "@casl/react";
import {AbilityContext} from "../helpers/Can";
import useCurrentUser from "../helpers/useCurrentUser";
import AssetGroupChooser from "../manage/AssetGroupChooser";
import {Formik} from "formik";
import CloudDownloadOutlined from "@ant-design/icons/lib/icons/CloudDownloadOutlined";
import pluralize from "pluralize";
import DownloadOutlined from "@ant-design/icons/lib/icons/DownloadOutlined";
import {Checkbox, Form, Radio} from "formik-antd";
import {useAssetGroupDispatch} from "../../contexts/AssetGroupContext";
import {useUploadsDispatch, useUploadsState} from "../../contexts/UploadsContext";
import {useStorageState} from "react-storage-hooks";
import {AppContext} from "../../contexts/AppContext";
import useGuestLogin from "../helpers/useGuestLogin";
import {useTranslation} from "react-i18next";
import BrandedButton from "../widgets/BrandedButton";

export default ({})=>{
    const { t } = useTranslation();

    const {state: sessionState, dispatch: sessionDispatch} = useContext(SessionContext);
    const {currentOrg} = sessionState;

    const currentUser = useCurrentUser()

    const assetGroupDispatch = useAssetGroupDispatch()

    const [visible, setVisible] = useState(false);

    const [loading, setLoading] = useState(true);

    const [contributions, setContributions] = useState([]);

    const navigate = useNavigate();

    const {uploads, fileAdds} = useUploadsState();
    const uploadsDispatch = useUploadsDispatch()

    const ability = useAbility(AbilityContext);
    const showStorageFolders = ability.can('manage', 'Asset')

    const [activePanel, setActivePanel] = useStorageState(sessionStorage, `activeUploadPanel`, [showStorageFolders ? 'storage-folders' : 'contribution-requests']);

    const [showButton, setShowButton] = useState(ability.can('manage', 'Asset'));

    useEffect(()=>{
        if(!currentOrg?.has_public_uploads && (!currentOrg || !currentUser)) return

        api.get('/api/can_upload').then(res => {
            setShowButton(res.data.can_upload)
            sessionDispatch({type: 'setCanUpload', canUpload: res.data.can_upload})

        }).catch(err => {
            if(err.response?.data) {
                notification.error({message: `Token Error: ${err.response.data}`})
                navigate('/')
            }
        })
    }, [currentOrg?.id, currentUser?.id])

    useEffect(()=> {
        if(!visible) return;

        setLoading(true);
        api.get('/api/contributions/featured').then(res => {
            setLoading(false);
            setContributions(res.data);
        })

    }, [visible]);

    const getPath = useOrgPath()

    const initUpload = (upload)=>{
        setVisible(false)
        navigate(getPath(`/upload/${upload.contribution.slug}/${upload.guid}`));
        assetGroupDispatch({type: 'setCurrentUpload', upload});

        if(uploads.new?.length) {
            uploadsDispatch({type:'triageNewFiles', upload})
        }
    }

    const createUpload = contribution => {
        api.post(`/api/contributions/${contribution.id}/uploads`).then(res => {
            message.success(t('message-starting-new-upload', 'Starting new Upload!'))
            initUpload(res.data)
        })
    }

    const guestLogin = useGuestLogin()

    const clickContribution = (contribution) => {
        // if no user, create guest:
        if(!currentUser) {
            guestLogin(()=>  createUpload(contribution))
        } else {
            createUpload(contribution)
        }
    }

    const [assetGroup, setAssetGroup] = useState()
    const setSelectedGroup = selectedGroup => {
        setAssetGroup(selectedGroup)
    }

    const [storageFolderUploadLoading, setStorageFolderUploadLoading] = useState()

    useEffect(()=>{
        if(uploads.new?.length) {
            setVisible(true)
        }

    }, [uploads.new?.length])

    const cancel = ()=>{
        uploadsDispatch({type:'cancelNewFiles'})
        setVisible(false)
    }

    return (<>
        {showButton && (
            <BrandedButton
                type={'primary'}
                icon={<CloudUploadOutlined/>}
                onClick={()=> setVisible(true)}
                shape={isMobile() && 'circle'}
                id={'app-header-upload-btn'}
            >
                {isBrowser() && t('upload','Upload')}
            </BrandedButton>
        )}
        <Modal
            title={
                <>
                    <CloudUploadOutlined/> {t('new-upload','New Upload')} <HelpPopover code={'new-upload-header'}/>

                    {currentUser && (
                        <div style={{float:'right', marginRight:'2em'}}>
                            <OrgLink to={'/uploads'} onClick={()=>setVisible(false)}>
                                <ClockCircleOutlined/> {t('link.view-my-past-uploads','View My Past Uploads...')}
                            </OrgLink>
                        </div>
                    )}
                </>
            }
            open={visible}
            onCancel={cancel}
            footer={null}
            width={isMobile() ? '100%' : 800}
            destroyOnClose
        >
            {/*TODO: this alert*/}
            {!!uploads.new?.length && (
                <Alert showIcon message={`Choose destination for ${uploads.new.length} ${pluralize('file', uploads.new.length)}:`} style={{marginBottom:'1em'}}/>
            )}
            <Collapse
                accordion
                defaultActiveKey={showStorageFolders ? 'storage-folders' : 'contribution-requests'}
                activeKey={activePanel}
                onChange={keys => setActivePanel(keys)}
            >
                {showStorageFolders && (
                    <Collapse.Panel header={t('file-vault', 'File Vault')} key={'storage-folders'}>
                        <Formik
                            initialValues={{}}
                            enableReinitialize={true}
                            onSubmit={(values, actions) => {
                                setStorageFolderUploadLoading(true)
                                api.post(`/api/contributions/${assetGroup.contribution_id}/uploads`,{
                                    asset_group_folder_id: assetGroup?.folder?.id
                                }).then(res => {
                                    setStorageFolderUploadLoading(false)
                                    message.success(t('message-starting-new-file-vault-upload','Starting new File Vault Upload!'))
                                    initUpload(res.data)
                                })
                            }}
                        >
                            {({values, submitForm, handleSubmit, isSubmitting}) => {
                                return (
                                    <Space direction={'vertical'} style={{width:'100%'}}>
                                        <AssetGroupChooser
                                            type={'storage_folders'}
                                            fieldName={'asset_group_id'}
                                            setSelectedGroup={setSelectedGroup}
                                        />

                                        <Button onClick={submitForm} ghost type={'primary'} disabled={!values.asset_group_id} icon={<CloudUploadOutlined/>} loading={storageFolderUploadLoading}>
                                            {t('button-start-file-vault-upload','Start File Vault Upload')}
                                        </Button>
                                    </Space>
                                )
                            }}
                        </Formik>
                    </Collapse.Panel>
                )}

                {!!contributions?.length && (
                    <Collapse.Panel header={<>{t('contribution-requests','Contribution Requests')} <Tag>{contributions.length}</Tag></>} key={'contribution-requests'} id={'upload-panel-contribution-requests'}>
                        <Skeleton active loading={loading}>
                            <List
                                grid={{gutter:16, column: isMobile() ? 1 : 3}}
                                locale={{ emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={t('error-no-upload-pipelines','No upload pipelines enabled yet...')}/> }}
                                dataSource={contributions}
                                renderItem={item => (
                                    <List.Item>
                                        <Card
                                            id={`upload-contribution-${item.id}`}
                                            hoverable
                                            title={item.name}
                                            onClick={() => clickContribution(item)}
                                        >
                                            {item.user_group && (
                                                <p>
                                                    <UserGroupIcon/> {item.user_group.name}
                                                </p>
                                            )}

                                            {item.text}

                                            <br/>

                                            {item.slug &&
                                            <small>/{item.slug}</small>
                                            }

                                            {isMobile() && (
                                                <Button type={'primary'} block icon={<CheckOutlined/>}>{t('button-start-upload','Start Upload')}</Button>
                                            )}
                                        </Card>
                                    </List.Item>
                                )}
                            />
                        </Skeleton>
                    </Collapse.Panel>
                )}

                {currentUser && !currentUser.guest && (
                    <Collapse.Panel header={t('lightboxes','Lightboxes')} key={'lightboxes'} id={'upload-panel-lightboxes'}>
                        <Formik
                            initialValues={{}}
                            enableReinitialize={true}
                            onSubmit={(values, actions) => {
                                setStorageFolderUploadLoading(true)
                                api.post(`/api/contributions/${assetGroup.contribution_id}/uploads`,{
                                    asset_group_folder_id: assetGroup?.folder?.id
                                }).then(res => {
                                    setStorageFolderUploadLoading(false)
                                    message.success(t('message-starting-new-lightbox-upload','Starting new Lightbox Upload!'))
                                    initUpload(res.data)
                                })
                            }}
                        >
                            {({values, submitForm, handleSubmit, isSubmitting}) => {
                                return (
                                    <Space direction={'vertical'} style={{width:'100%'}}>
                                        <AssetGroupChooser
                                            type={'lightboxes'}
                                            fieldName={'asset_group_id'}
                                            setSelectedGroup={setSelectedGroup}
                                            selectableIf={lb => !lb.organizer && lb.sub_type != 'project' && lb.sub_type != 'folder' && (lb.enable_contribution || lb.has_uploadable_descendants) }
                                            dimIf={lb => !lb.enable_contribution && !lb.has_uploadable_descendants }
                                        />
                                        <Button onClick={submitForm} ghost type={'primary'} disabled={!values.asset_group_id} icon={<CloudUploadOutlined/>} loading={storageFolderUploadLoading}>
                                            {t('button-start-lightbox-upload','Start Lightbox Upload')}
                                        </Button>
                                    </Space>
                                )
                            }}
                        </Formik>
                    </Collapse.Panel>
                )}

            </Collapse>
        </Modal>
    </>);
}