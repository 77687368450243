import React, {useContext, useEffect, useRef, useState} from "react";

import {useLoadedAssetsDispatch, useLoadedAssetsState} from "../../contexts/LoadedAssetsContext";
import {useAggsDispatch} from "../../contexts/AggsContext";
import {SessionContext} from "../../contexts/SessionContext";
import {useAssetGroupState} from "../../contexts/AssetGroupContext";
import {useFilters} from "../helpers/useFilters";
import {useParams} from "react-router-dom";
import api from "../api";
import {useAssetsDispatch, useAssetsState} from "../../contexts/AssetsContext";
import {useAssetsPageDispatch, useAssetsPageState} from "../../contexts/AssetsPageContext";
import {AppContext} from "../../contexts/AppContext";
import {useSelectedAssetsDispatch, useSelectedAssetsState} from "../../contexts/SelectedAssetsContext";
import {notification} from "antd";
import useCurrentOrg from "../helpers/useCurrentOrg";

export default ({})=>{
    // Grand Central for loading and new assets

    const {page} = useAssetsPageState()
    const assetsPageDispatch = useAssetsPageDispatch()

    // Only dispatch Aggs on initial Assets load:

    const {reloads} = useLoadedAssetsState()

    const {state} = useContext(SessionContext);
    const {viewMode, taxonomyFilterModes, assetSortOrder, currentOrg} = state;

    const {
        currentAssetGroup,
        currentWorkflowStep,
        currentUpload,
        viewAllType,
        currentUploadType,
        currentContribution,
        myCurrentContribution,
        currentTag,
        currentFederationOrg,
    } = useAssetGroupState()

    const {location} = useFilters()

    // Prevent initial non-asset-group API call, before we have Group details
    const {slug, type, uploadGuid, contributionId, myContributionId, uploadType, shareCode, accessRequestId, tagId, folder, organizationSlug} = useParams();

    const {assetLoader} = useAssetLoader()

    // Set Page 1 for new searches / containers / etc:
    // Note: this will fire twice for Groups w/ Workflows, because the direct group descendents are being returned

    let reloading;

    useEffect(()=>{
        if(page != 1) {
            reloading = true
            assetsPageDispatch({type:'reload'})
        }
    }, [currentOrg?.id, location.search, taxonomyFilterModes,
        currentAssetGroup?.id, currentAssetGroup?.folder?.id, currentWorkflowStep?.id, viewAllType, viewMode, assetSortOrder,
        currentUploadType, currentContribution?.id, myCurrentContribution?.id, currentTag?.id])

    useEffect(()=> {
        if(reloads > 0) assetsPageDispatch({type:'reload'})
    }, [reloads])

    useEffect(() => {
        // Prevent previous page from loading when changing browse params:
        if(reloading && page != 1) return

        if(currentAssetGroup && !slug && !shareCode && !accessRequestId) return;
        if(slug && type && !currentAssetGroup && !viewAllType && !currentUploadType) return;
        if(!viewAllType && !currentUploadType && slug && slug != currentAssetGroup?.slug) return;
        if(uploadGuid && !currentUpload) return;
        if(contributionId && !currentContribution) return
        if(myContributionId && !myCurrentContribution) return
        if(uploadType && !currentUploadType) return
        if(shareCode && !currentAssetGroup) return;
        if(tagId && !currentTag) return;

        assetLoader()

    }, [currentOrg?.id, location.search, taxonomyFilterModes, assetSortOrder?.sort, assetSortOrder?.direction,
        currentAssetGroup?.id, currentAssetGroup?.folder?.id, currentWorkflowStep?.id, currentUpload?.id, viewAllType,
        currentUploadType, currentContribution?.id, myCurrentContribution?.id, currentTag?.id, reloads, viewMode === 'map', currentFederationOrg?.id]);

    return <></>
}

const assetsPerPage = 25

let latestAssetLoad = 0
let snapshotTimestamp = null

const useAssetLoader = ()=> {
    const {
        currentAssetGroup,
        currentWorkflowStep,
        currentUpload,
        viewAllType,
        currentUploadType,
        currentContribution,
        myCurrentContribution,
        currentTag,
        currentFederationOrg,
    } = useAssetGroupState()

    const {state} = useContext(SessionContext);
    const {taxonomyFilterModes, assetSortOrder, viewMode} = state;
    const currentOrg = useCurrentOrg()

    const loadedAssetsDispatch = useLoadedAssetsDispatch();
    const assetsDispatch = useAssetsDispatch();
    const aggsDispatch = useAggsDispatch()
    const {filters} = useFilters()

    const selectedAssetsDispatch = useSelectedAssetsDispatch()

    const assetLoader = ({page= 1, aggregatesOnly, cb}={})=> {
        console.log('loading page:', page, currentUploadType)

        if(page === 1 && !aggregatesOnly) {
            loadedAssetsDispatch({type: 'assetsLoading'});
            snapshotTimestamp = Date.now()
        }

        // HACK: prevent re-renders when selecting assets
        const selectedAssetIds = JSON.parse(sessionStorage.getItem(`selected-assets-reducer-${currentOrg?.id}`))?.selectedAssetIds

        let params = {
            page,
            aggregatesOnly,
            per_page: assetsPerPage,
            asset_group_id: currentAssetGroup?.id,
            workflow_step_id: currentWorkflowStep?.asset_group_id == currentAssetGroup?.asset_group_id ? currentWorkflowStep?.id : null,
            upload_id: currentUpload?.id,
            taxonomy_filter_modes: taxonomyFilterModes,
            view_all: viewAllType,
            upload_type: currentUploadType,
            my_contribution_id: myCurrentContribution?.id,
            contribution_id: currentContribution?.id,
            sort: assetSortOrder?.sort,
            direction: assetSortOrder?.direction,
            gps: viewMode == 'map',
            selected_asset_ids: selectedAssetIds,
            taxonomy_tag_id: currentTag?.id,
            snapshot_timestamp: snapshotTimestamp,
            ...filters,
        };

        if(currentFederationOrg?.id) params.organization_id = currentFederationOrg.id;

        if(page === 1) params.include_totals = true

        if(currentAssetGroup) {
            const field = {
                'StorageFolder': 'storage_folder_id',
                'Collection': 'collection_id',
                'Lightbox': 'lightbox_id'
            }[currentAssetGroup.type]

            params[field] = currentAssetGroup.folder?.id || currentAssetGroup.id;
            params.asset_group_organization_id = currentAssetGroup.organization_id

            if(['access_request', 'access_grant'].includes(currentAssetGroup.sub_type)) params.access_request_id = currentAssetGroup.access_request_id

            if(currentAssetGroup.folder) params.lightbox_folder_id = currentAssetGroup.folder.id
        }

        if(page === 1) latestAssetLoad += 1;
        const assetLoadNumber = latestAssetLoad;

        window.currentSearchParams = params

        return api.post('/api/assets/search', params).then(res => {
            // Prevent old loads from clobbering new ones:
            if(page == 1 && assetLoadNumber != latestAssetLoad) return cb && cb();

            if(res.data.error) {
                notification.error({message:'Search Error', description: res.data.error, duration: 0})
                loadedAssetsDispatch({type: 'assetsLoaded', assets: [], page, per_page: assetsPerPage, total: 0});
                return cb && cb(0)
            }

            if(res.data.assets) {
                assetsDispatch({type: 'assetsLoaded', assets: res.data.assets})
                loadedAssetsDispatch({
                    type: 'assetsLoaded',
                    assets: res.data.assets,
                    page,
                    per_page: assetsPerPage,
                    total: res.data.total_entries
                });
            }

            const total = res.data.total_display || res.data.total_entries

            if(res.data.aggs) {
                aggsDispatch({
                    type: 'aggsLoaded',
                    aggs: res.data.aggs,
                    coords: res.data.coords,
                    allAssetCounts: res.data.all_asset_counts,
                    total,
                    params
                })
            }

            if(page == 1 && res.data.ids_in_selection) {
                const ids = filters.selection === 'unselected' ? selectedAssetIds : res.data.ids_in_selection
                selectedAssetsDispatch({type: 'setSelection', ids})
            }

            cb && cb(total)
        })
    }

    const aggsLoader = ({page, cb}={})=>{
        assetLoader({page, aggregatesOnly: true, cb})
    }

    return {assetLoader, assetsPerPage, aggsLoader}
}

export {useAssetLoader}

