import React from "react";

import {
    FolderOpenOutlined,
    FolderOutlined,
    FolderFilled,
    NodeIndexOutlined,
    DeploymentUnitOutlined,
    InfoCircleOutlined,
    ApartmentOutlined,
    ReloadOutlined,
    FolderOpenFilled,
    BookOutlined, SettingOutlined, HomeOutlined, DownloadOutlined, CloudDownloadOutlined,
} from "@ant-design/icons";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faBriefcase,
    faArchive, faUsers, faInbox, faSort, faTrashAlt, faKey,
} from '@fortawesome/free-solid-svg-icons'

import {
    faBooks, faClone,
    faFileImport,
    faFolderMinus,
    faFolderTree,
    faThumbsDown, faThumbsUp
} from '@fortawesome/pro-solid-svg-icons'

import {
    faRectangleLandscape,
    faRectanglePortrait,
    faRectangleWide,
    faSquare,
    faBalanceScale,
    faProjectDiagram,
    faFileInvoice,
    faKeyboard,
    faPhotoVideo,
    faImages,
    faHome,
    faLayerPlus,
    faThumbsUp as faRegularThumbsUp,
    faThumbsDown as faRegularThumbsDown,
    faWater,
    faDewpoint,
    faPallet,
    faPalette,
    faCode,
    faSlash,
    faEyeSlash,
    faChartNetwork,
    faServer,
    faShare,
    faChevronCircleLeft,
    faChevronCircleRight,
    faShareSquare,
    faCodeMerge,
    faUniversalAccess,
    faSmile,
    faLink,
} from '@fortawesome/pro-regular-svg-icons'

import AppstoreAddOutlined from "@ant-design/icons/lib/icons/AppstoreAddOutlined";
import CloudUploadOutlined from "@ant-design/icons/lib/icons/CloudUploadOutlined";
import {NavLink} from "react-router-dom-v5-compat";
import {Badge, Popover} from "antd";

export const UserGroupIcon = ()=> <FontAwesomeIcon icon={faUsers} className={'anticon'}/>;
export const OrganizerIcon = (props)=> <FolderOutlined {...props}/>;
export const StorageFolderIcon = (props)=> <FolderOpenFilled {...props}/>;
export const CollectionIcon = (props)=> <AppstoreAddOutlined {...props}/>;
export const LibraryIcon = (props)=> <FontAwesomeIcon icon={faBooks} className={`anticon ${props.className}`} {...props}/>
export const LightboxIcon = (props)=> <FontAwesomeIcon icon={faArchive} className={'anticon'} {...props}/>;
export const ProjectIcon = (props)=> <FontAwesomeIcon icon={faBriefcase} className={`anticon ${props.className}`} {...props}/>;
export const BinIcon = ()=> <FontAwesomeIcon icon={faInbox} className={'anticon'}/>;
export const TaxonomyIcon = (props)=> <ApartmentOutlined {...props}/>;
export const SynonymIcon = (props)=> <FontAwesomeIcon icon={faProjectDiagram} className={'anticon'} {...props}/>

export const SortIcon = ()=> <FontAwesomeIcon icon={faSort} className={'anticon'}/>;
export const DeleteIcon = props => <FontAwesomeIcon icon={faTrashAlt} className={'anticon'} {...props}/>;

export const WorkflowIcon = ()=> <NodeIndexOutlined/>;
export const RightsIcon = (props)=> <FontAwesomeIcon icon={faBalanceScale} className={'anticon'} {...props} />;
export const PermissionsIcon = (props)=> <FontAwesomeIcon icon={faKey} className={'anticon'} {...props}/>;
export const FolderTreeIcon = (props)=> <FontAwesomeIcon icon={faFolderTree} className={'anticon'} {...props}/>;
export const ContributionIcon = ()=> <CloudUploadOutlined/>

export const GreyBadge = ({count, style={}}) => <Badge count={count} style={{backgroundColor:'#fff', color:'#999', ...style}} overflowCount={1000000}/>

export const GreyInfo = () => <InfoCircleOutlined style={{marginLeft:'0.5em', color:'rgb(176, 176, 176)'}}/>
export const GreySettings = ({style}) => <SettingOutlined style={{marginRight:'0.5em', color:'rgb(176, 176, 176)', ...style}}/>

export const RefreshIcon = props => <ReloadOutlined style={{color:'rgb(176, 176, 176)', ...(props.style || {})}}/>

export const ShareIcon = props => <FontAwesomeIcon icon={faShareSquare} className={'anticon'} {...props}/>
export const ImportIcon = props => <FontAwesomeIcon icon={faFileImport} className={'anticon'} {...props}/>

export const CustomMetaIcon = props => <FontAwesomeIcon icon={faLayerPlus} className={'anticon'} {...props}/>
export const ArchiveIcon = props => <FontAwesomeIcon icon={faFolderMinus} className={'anticon'} {...props}/>
export const CloneIcon = props => <FontAwesomeIcon icon={faClone} className={'anticon'} {...props}/>

export const AspectLandscapeIcon = props => <FontAwesomeIcon icon={faRectangleLandscape} className={'anticon'} {...props}/>
export const AspectPanoramaIcon = props => <FontAwesomeIcon icon={faRectangleWide} className={'anticon'} {...props}/>
export const AspectPortraitIcon = props => <FontAwesomeIcon icon={faRectanglePortrait} className={'anticon'} {...props}/>
export const AspectSquareIcon = props => <FontAwesomeIcon icon={faSquare} className={'anticon'} {...props}/>

export const InvoiceIcon = props => <FontAwesomeIcon icon={faFileInvoice} className={'anticon'} {...props}/>
export const KeyboardIcon = props => <FontAwesomeIcon icon={faKeyboard} className={'anticon'} {...props}/>
export const ExploreIcon = props => <HomeOutlined {...props}/>

export const LightroomClassicIcon = props => <img alt='lightroom-classic-logo' src={ImageUrls.lightroomClassicLogo} {...props} style={{width: 15, ...(props.style || {})}} />
export const LightroomIcon = props => <img alt='lightroom-logo' src={ImageUrls.lightroomLogo} {...props} style={{width: 15, ...(props.style || {})}} />

export const ThumbsUpIcon = props => <FontAwesomeIcon icon={props.regular ? faRegularThumbsUp : faThumbsUp} className={'anticon'} {...props}/>
export const ThumbsDownIcon = props => <FontAwesomeIcon icon={props.regular ? faRegularThumbsDown : faThumbsDown} className={'anticon'} {...props}/>

export const WatermarkIcon = props => <FontAwesomeIcon icon={faDewpoint} className={'anticon'} {...props}/>

export const ArtworkIcon = props => <FontAwesomeIcon icon={faPalette} className={'anticon'} {...props}/>
export const EmbedIcon = props => <FontAwesomeIcon icon={faCode} className={'anticon'} {...props}/>
export const GraphIcon = props => <FontAwesomeIcon icon={faChartNetwork} className={'anticon'} {...props}/>
export const FtpIcon = props => <FontAwesomeIcon icon={faServer} className={'anticon'} {...props}/>

export const BlockView = props => <FontAwesomeIcon icon={faEyeSlash} className={'anticon'} {...props}/>
export const BlockDownload = props => {
    return (
        <div style={{display:'inline'}} {...props}>
            <CloudDownloadOutlined {...props} />
            <FontAwesomeIcon icon={faSlash} className={'anticon'} style={{...(props.style || {}), marginLeft:-15}}/>
        </div>
    )
}

import FrameSvg from '../../images/frameio-symbol-black.svg'
export const FrameIcon = props => <img src={FrameSvg} {...props} style={{width:20, marginLeft:-10, marginTop:-3, ...(props.style || {})}}/>

import BoxSvg from '../../images/box-logo.svg'
export const BoxIcon = props => <img src={BoxSvg} {...props} style={{width:15, marginTop:-3, ...(props.style || {})}}/>

import GoogleDrivePng from '../../images/google-drive.png'
export const GoogleDriveIcon = props => <img src={GoogleDrivePng} {...props} style={{width:18, marginTop:-2, marginRight: 0, marginLeft:-2, ...(props.style || {})}}/>

export const FrameStatusIcon = ({status})=> <div style={{width:10, height:10, borderRadius: '50%', display: 'inline-block', marginRight: '.5em', backgroundColor: Colors.frameStatuses[status]}}/>

export const LeftIcon = props => <FontAwesomeIcon icon={faChevronCircleLeft} className={'anticon'} {...props}/>
export const RightIcon = props => <FontAwesomeIcon icon={faChevronCircleRight} className={'anticon'} {...props}/>

export const MergeIcon = props => <FontAwesomeIcon icon={faCodeMerge} className={'anticon'} {...props}/>

export const AccessibilityIcon = props => <FontAwesomeIcon icon={faUniversalAccess} className={'anticon'} {...props}/>
export const FaceIcon = props => <FontAwesomeIcon icon={faSmile} className={'anticon'} {...props}/>

export const CollectionShareIcon = props => <FontAwesomeIcon icon={faLink} className={'anticon'} {...props}/>
