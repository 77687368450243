import {Formik} from "formik";
import api from "../api";
import {Alert, Button, Card, Divider, message} from "antd";
import * as Yup from "yup";
import {Checkbox, Form, FormItem, Input, SubmitButton} from "formik-antd";
import {LoadingOutlined, LockOutlined, PoweroffOutlined, UserOutlined} from "@ant-design/icons";
import {Link, useNavigate} from "react-router-dom-v5-compat";
import React, {useContext, useEffect, useState} from "react";
import {AppContext} from "../../contexts/AppContext";
import {SessionContext} from "../../contexts/SessionContext";
import useAfterLogin, {clearAfterLogin} from "../helpers/useAfterLogin";
import useCurrentOrg, {checkReindexing} from "../helpers/useCurrentOrg";
import useSwitchToUser from "../helpers/useSwitchToUser";
import queryString from "query-string";
import {useOrgPath} from "../helpers/OrgNavLink";
import {useTranslation} from "react-i18next";

export default ({invite, org, emailLogin, autoFocus=true})=>{
    const {t} = useTranslation();
    const {dispatch} = useContext(AppContext);
    const {dispatch: sessionDispatch} = useContext(SessionContext);
    const navigate = useNavigate();

    const [otpRequired, setOtpRequired] = useState()
    const [ssoLoginToken, setSsoLoginToken] = useState()

    const [loggedIn, setLoggedIn] = useState()

    // For Super Admin user switching:
    const { adminSwitchUser } = queryString.parse(location.search)
    const switchUser = useSwitchToUser()
    useEffect(()=> {
        if(adminSwitchUser) switchUser({id: adminSwitchUser});
    }, [adminSwitchUser])

    const getPath = useOrgPath()

    const getLandingPage = org => {
        let landingPage;

        switch(org.default_landing_page) {
            case 'explore':
                landingPage = '/explore';
                break;
            case 'homepage':
                landingPage = '/'
                break;
        }

        return landingPage
    }

    if(loggedIn) return <>Logged in!</>

    return (
        <Formik
            initialValues={{
                email: invite?.email,
                password: "",
                remember_me: false,
                invite_token: invite?.token,
                sso_login_token: ssoLoginToken,
            }}
            onSubmit={(values, actions) => {
                api({
                    method:'post',
                    url: '/api/login',
                    data: { user: values }
                }).then((res)=>{
                    console.warn(JSON.stringify(res.data))

                    if(res.data.otp_required) {
                        actions.setSubmitting(false)
                        return setOtpRequired(true)
                    }

                    if(res.headers.authorization) {
                        dispatch({
                            type:'login',
                            user: res.data,
                            jwt: res.headers.authorization.replace('Bearer ','')
                        });

                        // actions.resetForm()
                        message.success(t('message-logged-in','Logged In!'))

                        setLoggedIn(true)

                        const afterLogin = useAfterLogin()
                        console.log('afterLogin', afterLogin)

                        // Delay this to ensure Axios has the JWT header set for the API call:
                        setTimeout(()=>{
                            api.get(`/api/whoami`).then(res => {
                                if(invite) return;

                                const orgDest = org || res.data.last_organization

                                if(orgDest) {
                                    api.get(`/api/organizations/find?slug=${orgDest.slug}&login=true`).then(res => {
                                        sessionDispatch({type: 'set_org', org: res.data.organization, abilities: res.data.abilities});
                                        message.success(t(`message-welcome-back-to-org`,`Welcome back to {{title}}!`, {title: orgDest.title}));

                                        clearAfterLogin()

                                        navigate(afterLogin || getPath(getLandingPage(orgDest), orgDest))
                                    });
                                } else {
                                    clearAfterLogin()
                                    navigate(afterLogin || '/organizations')
                                }
                            })
                        }, 100)
                    } else {
                        message.error(values.otp_attempt ? t('error-incorrect-2fa-code','Incorrect 2FA code.') : t('error-incorrect-email-password','Incorrect email / password.'))
                    }
                    console.log(res)
                    actions.setSubmitting(false)
                }).catch((res)=>{
                    message.info(JSON.stringify(res))
                    console.log('ERROR', res)
                    actions.setSubmitting(false)
                })
            }}
            validationSchema={
                !otpRequired && Yup.object({
                    email: Yup.string().email().required(t('required','Required')),
                    password: Yup.string().required(t('required','Required')),
                })
            }
        >
            {({isSubmitting, setFieldValue}) => {

                const afterLogin = useAfterLogin()

                const [ssoClicked, setSsoClicked] = useState()
                const [loggingIn, setLoggingIn] = useState()

                useEffect(()=>{
                    if(location.hash == '#sso-callback') {
                        location.hash = ''
                        const ssoUser = localStorage.getItem('ssoUser')
                        const ssoOrg = localStorage.getItem('ssoOrg')
                        ssoUser && SSOCallback(JSON.parse(ssoUser), JSON.parse(ssoOrg))
                    }
                }, [])

                window.SSOCallback = (json, currentOrg=org)=>{
                    if(json.otp_required) {
                        setOtpRequired(json.otp_required)
                        setFieldValue('sso_login_token', json.sso_login_token)

                    } else {
                        setLoggingIn(true)

                        dispatch({
                            type:'login',
                            user: json,
                            jwt: json.jwt
                        });

                        api.get(`/api/organizations/find?slug=${currentOrg.slug}&login=true`).then(res => {
                            sessionDispatch({type: 'set_org', org: res.data.organization, abilities: res.data.abilities});
                            message.success(t(`message-welcome-back-to-org`,`Welcome back to {{title}}!`, {title: currentOrg.title}));
                            checkReindexing(res.data.organization)

                            clearAfterLogin()

                            navigate(afterLogin || getPath(getLandingPage(currentOrg)))
                        });
                    }
                }

                if(loggingIn) return (
                    <Alert type={'info'} message={<><LoadingOutlined/> {t('logging-in','Logging In')}...</>}/>
                )

                return (
                    <Form id={'login-form'}>
                        {otpRequired ? (
                            <>
                                <FormItem name="otp_attempt" required label={t('enter-2fa-code','Enter 2FA code')}>
                                    <Input
                                        autoFocus required
                                        autoComplete={'off'}
                                        placeholder={'123456'}
                                        name={'otp_attempt'}
                                    />
                                </FormItem>
                            </>
                        ) : (
                            <>
                                {org?.enable_sso && !emailLogin && (
                                    <div style={{marginBottom: '1em'}}>
                                        <a href={`/auth/${org.sso_type}` + (window.customDomainOrganizationSlug ? '' : `?organization_id=${org.id}`)} onClick={()=>{
                                            setSsoClicked(true)
                                        }}>
                                            <Button block type={'primary'} id={'sso-login-btn'} loading={ssoClicked}>
                                                {t('button-sso-login','Login via Single Sign On')}
                                            </Button>
                                        </a>

                                        {!org?.hide_password_login && (
                                            <Divider><small>{t('or-via-email-password','Or via Email/Password')}:</small></Divider>
                                        )}
                                    </div>
                                )}

                                {(!(org?.enable_sso && org?.hide_password_login) || emailLogin) && (
                                    <>
                                        <FormItem name="email" required>
                                            <Input
                                                autoFocus={autoFocus} required
                                                prefix={<UserOutlined style={{opacity: 0.5}}/>}
                                                type='email'
                                                autoComplete='email' name="email" placeholder={t("placeholder-email","Email")}
                                                aria-label={'email'}
                                            />
                                        </FormItem>

                                        <FormItem name="password" required>
                                            <Input.Password
                                                prefix={<LockOutlined style={{opacity: 0.5}}/>}
                                                autoComplete='password'
                                                name="password"
                                                placeholder={t("placeholder-password","Password")}
                                                aria-label={'password'}
                                            />
                                        </FormItem>

                                    </>
                                )}
                                {/*<FormItem name="remember_me" >*/}
                                {/*    <Checkbox name="remember_me">Keep me logged in</Checkbox>*/}
                                {/*</FormItem>*/}
                            </>
                        )}

                        {(!(org?.enable_sso && org?.hide_password_login) || otpRequired || emailLogin) && (
                            <FormItem name="submit" style={{marginBottom: 0}}>
                                {isSubmitting ? (
                                    <Button block type="primary" icon={<PoweroffOutlined/>} loading>{t('signing-in','Signing in')}...</Button>
                                ) : (
                                    <SubmitButton block id={'login-btn'}>{t('button-login','Login')}</SubmitButton>
                                )}
                            </FormItem>
                        )}
                    </Form>
                );
            }}
        </Formik>

    )
}