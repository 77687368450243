import React, {useContext, useEffect, useState} from "react";
import { Comment } from '@ant-design/compatible';
import {
    Empty,
    Badge,
    Tag,
    List,
    Modal,
    Button,
    notification as antNotification,
    Breadcrumb,
    Skeleton,
    Tooltip,
    Typography,
} from "antd";
import {FormOutlined, NotificationFilled} from "@ant-design/icons";
import {useNotificationsDispatch, useNotificationsState} from "../../contexts/NotificationsContext";
import CommentOutlined from "@ant-design/icons/lib/icons/CommentOutlined";
import User from "../helpers/User";
import {Link, useNavigate} from "react-router-dom-v5-compat";
import {CollectionIcon, LightboxIcon} from "../helpers/icons";
import api from "../api";
import NotificationOutlined from "@ant-design/icons/lib/icons/NotificationOutlined";
import TimeAgo from "../helpers/TimeAgo";
import AlertOutlined from "@ant-design/icons/lib/icons/AlertOutlined";
import CheckOutlined from "@ant-design/icons/lib/icons/CheckOutlined";
import {SessionContext} from "../../contexts/SessionContext";

import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile
} from "device-detect";
import {useOrgPath} from "../helpers/OrgNavLink";
import {BrandedText} from "../AppHeader";
import useCurrentUser from "../helpers/useCurrentUser";
import {useTranslation} from "react-i18next";

export default ({open, setOpen})=> {
    const {t} = useTranslation();
    const {state} = useContext(SessionContext);
    const {currentOrg} = state;

    const currentUser = useCurrentUser()

    const {newNotifications} = useNotificationsState()
    const notificationsDispatch = useNotificationsDispatch();

    const [count, setCount] = useState()
    const updateCount = ()=> {
        if(currentOrg) api('/api/notifications/count').then(res => setCount(res.data.unseen))
    }

    useEffect(()=>{
        updateCount()
    }, [newNotifications?.length, currentOrg?.slug])

    const navigate = useNavigate()

    const getPath = useOrgPath()

    const getCommentDescription = ({comment}) => {
        let path; 

        if(comment.lightbox) {
            path = getPath(`/explore/projects/${comment.lightbox.slug}#comments`)
        } else if(comment.collection) {
            path = getPath(`/explore/collections/${comment.collection?.slug}#comments`)
        }

        const onClick = ()=> {
            setVisible(false)
            if(setOpen) setOpen(false)
            navigate(path)
        }

        return (
            <>
                <User user={comment.user}/> {t('mentioned-you-in','mentioned you in')}: &nbsp;
                <a href={path} onClick={onClick}>
                    {comment.lightbox ? (
                      <><LightboxIcon/> {comment.lightbox.name}</>
                    ) : comment.collection ? (
                      <><CollectionIcon/> {comment.collection.name}</>
                    ) : null}
                </a>
            </>
        )
    }

    const getAssetGroupInviteDescription = ({user, lightbox, asset_group_invite}) => {
        const path = getPath(`/explore/projects/${lightbox.slug}`)

        const onClick = ()=> {
            setVisible(false)
            if(setOpen) setOpen(false)
            navigate(path)
        }

        return (
            <>
                <User user={user}/> invited you to: &nbsp;
                <a href={path} onClick={onClick}>
                    <LightboxIcon/> {lightbox.name}
                </a>
                {!!asset_group_invite.note?.length && <Typography.Text type={'secondary'} style={{marginLeft:'1em'}}>Note: {asset_group_invite.note}</Typography.Text>}
            </>
        )
    }

    const getAccessRequestDescription = ({access_request: accessRequest}) =>{
        const path = getPath(`/access-requests/${accessRequest.slug}`)

        const onClick = ()=> {
            setVisible(false)
            if(setOpen) setOpen(false)
            navigate(path)
        }

        if(accessRequest.user_id === currentUser?.id) {
            return (
                <>
                    Your Access Request has been finalized: &nbsp;
                    <a href={path} onClick={onClick}>
                        {accessRequest.name}
                    </a>
                </>
            )
        }

        return (
            <>
                <User user={accessRequest.user}/> has created an Access Request: &nbsp;
                <a href={path} onClick={onClick}>
                    {accessRequest.name}
                </a>
            </>
        )
    }

    const types = {
        CommentUser: {
            icon: <CommentOutlined/>,
            message: 'New Comment Mention',
            description: getCommentDescription
        },
        AccessRequest: {
            icon: <FormOutlined/>,
            message: 'New Access Request',
            description: getAccessRequestDescription
        },
        AssetGroupInvite: {
            icon: <LightboxIcon/>,
            message: 'New Lightbox Invite',
            description: getAssetGroupInviteDescription
        }
    }

    useEffect(()=>{
        if(!newNotifications.length) return;

        newNotifications.map(notification => {
            console.log(notification)
            const type = types[notification.what.what_type]

            antNotification.info({icon: type.icon, message: type.message, description: type.description(notification.what)})

            notificationsDispatch({type: 'notified', notification})
        })

    }, [newNotifications])

    const [visible, setVisible] = useState(false)

    useEffect(() => {
        if(open && !visible) setVisible(true)
    }, [open]);

    const clickNotificationsButton = ()=>{
        setVisible(true)
    }

    const onCancel = ()=> {
        setVisible(false)
        if(setOpen) setOpen(false)
    }

    const [notifications, setNotifications] = useState([])
    const [loading, setLoading] = useState(false)

    useEffect(()=>{
        if(!visible) return;

        setLoading(true)
        api('/api/notifications').then(res => {
            setNotifications(res.data)
            setLoading(false)
        })
    }, [visible])

    const [markingAllRead, setMarkingAllRead] = useState(false)
    const markAllRead = ()=>{
        setMarkingAllRead(true)
        api.post('/api/notifications/all_seen').then(res => {
            setMarkingAllRead(false)
            setNotifications(res.data)
            updateCount()
        })
    }

    const getNotificationDescription = notification => {
        switch(notification.what_type) {
            case 'CommentUser':
                return getCommentDescription(notification)
            case 'AssetGroupInvite':
                return getAssetGroupInviteDescription(notification)
            case 'AccessRequest':
                return getAccessRequestDescription(notification)
        }
    }

    return (<>
        <Tooltip title={isBrowser() && <span style={{color:'black'}}>{t('notifications','Notifications')}</span>} placement='bottom' color={'white'}>
            <Badge
                count={count}
                offset={isMobile() ? [-17,16] : [0,0]}
            >
                <Button
                    icon={<BrandedText><NotificationFilled/></BrandedText>}
                    type={'text'}
                    style={{color:'white'}}
                    onClick={clickNotificationsButton}
                    tabIndex={-1}
                    shape={'circle'}
                />
            </Badge>
        </Tooltip>
        <Modal
            title={
                <>
                    <NotificationOutlined/> {t('notifications','Notifications')}
                    &nbsp;&nbsp;
                    {count > 0 && (
                        <Button ghost type='primary' onClick={markAllRead} icon={<CheckOutlined/>} loading={markingAllRead} size={'small'}>{t('mark-all-read','Mark All Read')}</Button>
                    )}
                </>
            }
            open={visible}
            onCancel={onCancel}
            footer={null}
            width={isMobile() ? '100%' : '50%'}
            styles={{body: {padding: isMobile() && 0}}}
        >
            <Skeleton active loading={loading}>
                <List
                    size={'small'}
                    itemLayout="horizontal"
                    locale={{ emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={'None yet...'}/> }}
                    dataSource={notifications}
                    renderItem={item => {
                        const [marking, setMarking] = useState(false)
                        const markRead = ()=>{
                            setMarking(true)
                            api.post(`/api/notifications/${item.id}/seen`).then(res => {
                                setMarking(false)
                                const notification = _.find(notifications, {id: item.id})
                                notification.seen_at = true
                                setNotifications([...notifications])
                                setCount(count => count - 1)
                            })
                        }

                        const markUnread = ()=>{
                            setMarking(true)
                            api.post(`/api/notifications/${item.id}/unseen`).then(res => {
                                setMarking(false)
                                const notification = _.find(notifications, {id: item.id})
                                notification.seen_at = null
                                setNotifications([...notifications])
                                setCount(count => count + 1)
                            })
                        }

                        return (
                            <List.Item key={item.id}>
                                <div style={{width:'100%'}}>
                                    <Typography.Text type={'secondary'}>
                                        <small>{<TimeAgo date={item.created_at}/>}</small>
                                    </Typography.Text>
                                    <br/>
                                    {!item.seen_at && <Tag color={'red'} icon={<AlertOutlined/>}>New</Tag>}

                                    {getNotificationDescription(item)}

                                    <div style={{float:'right'}}>
                                        {!item.seen_at && (
                                            <Button ghost type='primary' size={'small'} icon={<CheckOutlined/>} onClick={markRead} loading={marking}>
                                                {t('mark-read','Mark Read')}
                                            </Button>
                                        ) || (
                                            <Button size={'small'} icon={<AlertOutlined/>} onClick={markUnread} loading={marking}>
                                                {t('mark-unread','Mark Unread')}
                                            </Button>
                                        )}
                                    </div>
                                </div>
                            </List.Item>
                        );
                    }}
                >

                </List>

            </Skeleton>
        </Modal>
    </>);
}

const NotificationItem = ({item})=> {

}